import React, { useState, useEffect } from "react";
import xicon from "/public/Icons/x.png";
import mailicon from "/public/Icons/mail-opened.png";
import axios from "axios";
import { BASE_URL } from "../config";
import logo from "/public/Images/logo.png";
import { motion, AnimatePresence } from "framer-motion";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../redux/reducer/authSlice";
import notificationSoundFile from "/public/sounds/notificationSoundFile.mp3";
import { IoMdNotifications } from "react-icons/io";

const CustomToast = ({ title, message }) => {
  return (
    <div className="relative flex flex-col items-end p-4 bg-white  rounded-lg max-w-md w-full">
      <strong className="text-lg text-menu-default">{title}</strong>
      <p className="text-gray-500">{message}</p>
      <IoMdNotifications className="absolute left-2 top-2 text-menu-default w-5 h-5" />
    </div>
  );
};

const Notifications = ({ isOpen, updateUnreadCount }) => {
  const [noti, setNoti] = useState([]);
  const dispatch = useDispatch();
  const [unReadCount, setUnReadCount] = useState();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const { user, error } = useSelector((state) => state.auth);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19).replace("T", " ");
  };
  const notificationSound = new Audio(notificationSoundFile);

  const getNotification = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/notification`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        setNoti(res.data.notifications);
        setUnReadCount(res.data.unread_count);
        updateUnreadCount(res.data.unread_count); // Update unread count in Navbar
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(isAuthenticated());
    }

    getNotification();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const pusher = new Pusher("782989169b1064e404db", {
      cluster: "eu",
      authEndpoint: "/broadcasting/auth",
      auth: {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      },
    });

    const channel = pusher.subscribe("notifications");
    // Bind to an event
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        if (user?.user?.id == data.user_id) {
          // Play notification sound
          notificationSound.play().catch((err) => console.log(err));

          // Display notification to user
          toast(<CustomToast title={data.title} message={data.message} />, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          });
        }

        // Increment the new notifications count
        setNewNotificationsCount((prevCount) => prevCount + 1);
        getNotification();
      }
    );
    // Clean up the Pusher instance when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const isRead = (e, id) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}/notification/${id}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      )
      .then(() => {
        getNotification();
      });
  };

  const handleDeleteNotification = (id) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}/notification/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then(() => {
        // Trigger fade out by removing the notification from state
        setNoti((prevNoti) =>
          prevNoti.filter((notification) => notification.id !== id)
        );
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
      });
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="absolute left-0 top-10 lg:right-[-1rem] mt-2 w-80 bg-white rounded-lg shadow-lg z-50 max-h-80 overflow-auto scrollable-container"
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <div className="flex items-center space-x-2 space-x-reverse">
              <img src={mailicon} alt="" />
              <span className="text-xs text-blue-500 bg-blue-100 rounded-md px-2 py-1">
                {unReadCount} New
              </span>
            </div>
            <h2 className="text-lg font-semibold">Notifications</h2>
          </div>

          <AnimatePresence>
            {noti
              .slice()
              .reverse()
              .map((n) => (
                <motion.div
                  key={n.id}
                  onClick={(e) => isRead(e, n.id)}
                  initial={{ opacity: 1, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.3 }}
                  className={`flex items-start p-4 ${
                    n.is_read === 0 ? "bg-gray-100" : "bg-white"
                  } border-b border-gray-200`}
                >
                  <div className="me-3">
                    <button
                      onClick={() => handleDeleteNotification(n.id)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <img src={xicon} alt="" />
                    </button>
                  </div>

                  <div className="relative me-4 flex-1">
                    <h3 className="text-sm text-left font-semibold">
                      {n.title}
                    </h3>
                    <p className="text-sm text-left text-gray-500">
                      {n.message}
                    </p>
                    <p className="text-xs text-left text-gray-400 mt-1">
                      {formatDate(n.created_at)}
                    </p>
                    <span
                      className={`absolute top-1 right-0 p-1 ${
                        n.is_read ? "bg-gray-300" : "bg-blue-500"
                      } rounded`}
                    ></span>
                  </div>

                  <div className="flex-shrink-0">
                    {n.image ? (
                      <img
                        className="w-10 h-10 rounded-full"
                        src={n.image}
                        alt="User profile"
                      />
                    ) : (
                      <img
                        src={logo}
                        className="w-10 h-10 rounded-full"
                        alt="Logo"
                      />
                    )}
                  </div>
                </motion.div>
              ))}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Notifications;
