import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isAuthenticated } from "../redux/reducer/authSlice";

const AdminDashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");
    // if (token) {
    //   dispatch(isAuthenticated());
    // } else {
    //   navigate("/login");
    // }
  }, [dispatch]);
  return (
    <div className="App bg-home flex h-full min-h-screen px-2">
      <Sidebar />
      <section className={"lg:w-5/6 lg:px-6 w-full lg:ms-[17%] "}>
        <Navbar />

        <div>
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default AdminDashboardLayout;
