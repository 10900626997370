

const ReturnPolicy = () => {
  return (
    <div className=" mx-auto p-6 bg-white rounded-lg ">
      <h1 className="text-3xl font-semibold text-center text-gray-800 my-12">سياسة الإسترجاع والإستبدال</h1>

      <p className="text-lg text-gray-700 mb-4">
        في سبايب، نسعى جاهدين لضمان رضا عملائنا. إذا لم تكن راضيًا عن المنتج الذي تم شراؤه، نقدم لك سياسة استرجاع واستبدال واضحة وبسيطة.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">شروط الاسترجاع والاستبدال:</h2>
      <ul className="list-inside list-disc text-lg text-gray-700">
        <li>يجب أن يكون المنتج في حالته الأصلية، غير مستخدم، وفي عبوته الأصلية.</li>
        <li>يحق لـ سبايب رفض استلام أو تعويض أي منتج متضرر أو في حالة غير حالته الأصلية عند الشراء.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">المنتجات المتضررة أثناء الشحن:</h2>
      <p className="text-lg text-gray-700 mb-4">
        تضرر المنتجات أثناء عملية الشحن يقع على عاتق شركة الشحن التي تم اختيارها من قبل العميل.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">مدة الاسترجاع والاستبدال:</h2>
      <ul className="list-inside list-disc text-lg text-gray-700">
        <li>الاسترجاع: يمكن للعميل استرجاع المنتج خلال ٧ أيام من وقت استلامه.</li>
        <li>الاستبدال: يمكن للعميل استبدال المنتج خلال ٧ أيام من وقت استلامه.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">إجراءات استرجاع الأموال:</h2>
      <p className="text-lg text-gray-700 mb-4">
        بعد استلام شركة الشحن للمنتج من العميل، سيتم إعادة قيمة المنتج خلال 14 يومًا أو بحسب الأنظمة البنكية.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">مصاريف الشحن:</h2>
      <p className="text-lg text-gray-700 mb-4">
        يتحمل العميل جميع مصاريف الشحن (الإرسال والإرجاع) المترتبة على الاسترجاع والاستبدال.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">التواصل مع خدمة العملاء:</h2>
      <p className="text-lg text-gray-700 mb-4">
        على العميل التواصل مع خدمة العملاء للتنسيق مع فريقنا، الذي سيوفر له تعليمات الاسترجاع والاستبدال عبر البريد الإلكتروني: <a href="mailto:info@sbyb-sa.com" className="text-blue-500">info@sbyb-sa.com</a>
      </p>
    </div>
  );
};

export default ReturnPolicy;
