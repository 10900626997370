import { Routes, Route, useNavigate } from "react-router-dom";
import { lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "./redux/reducer/authSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackagesAdmin from "./AdminDashboard/PackagesAdmin";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import AdminDashboardLayout from "./AdminDashboard/AdminDashboardLayout";
import AdminProductPage from "./AdminDashboard/AdminProductPage";
import AddProductPage from "./AdminDashboard/components/AddProductPage";
import AdminBlogs from "./AdminDashboard/AdminBlogs";
import SuccessPayment from "./pages/SuccessPayment";
import ErrorPayment from "./pages/ErrorPayment";
import AdminCategoriesPage from "./AdminDashboard/AdminCategoriesPage";
import AddOrEditPackage from "./AdminDashboard/components/AddOrEditPackage";
import EditPackageWrapper from "./AdminDashboard/components/EditPackageWrapper";
import Notifications from "./AdminDashboard/Notifications";
import AdminTickets from "./AdminDashboard/AdminTickets";
import ShowGlobalProduct from "./components/ShowGlobalProduct";
import { toast } from "react-toastify";
import Employees from "./AdminDashboard/Employees";
import RolesPage from "./AdminDashboard/RolesPage";
import ComplaintForm from "./website/ComplaintForm";
import Complaints from "./AdminDashboard/Complaints";
import ReturnPolicy from "./website/ReturnPolicy";
// Dashboard import
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Products = lazy(() => import("./pages/Products"));
const MyProducts = lazy(() => import("./pages/MyProducts"));
const EditProduct = lazy(() => import("./pages/EditProduct"));
const Orders = lazy(() => import("./pages/Orders"));
const DashBoardLayOut = lazy(() => import("./pages/DashBoardLayOut"));
const Addedproducts = lazy(() => import("./pages/Addedproducts"));
const Addproduct = lazy(() => import("./pages/Addproduct"));
const SubscriptionPlan = lazy(() => import("./pages/SubscriptionPlan"));
const Tickets = lazy(() => import("./pages/Tickets"));
const AddTicket = lazy(() => import("./pages/AddTicket"));
const Wallet = lazy(() => import("./pages/Wallet"));
const Integrations = lazy(() => import("./pages/Integrations"));
const Subscripe = lazy(() => import("./pages/Subscripe"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const Cart = lazy(() => import("./pages/Cart"));
const ShowProduct = lazy(() => import("./pages/ShowProduct"));

const Users = lazy(() => import("./AdminDashboard/Users"));

// Login and Register
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));

//Home page import
const Home = lazy(() => import("./website/Home"));
const Weblayout = lazy(() => import("./website/Weblayout"));
const Subscription = lazy(() => import("./website/Subscription"));
const BLog = lazy(() => import("./website/Blog"));
const BLogDetails = lazy(() => import("./website/BlogDetails"));
const PrivacyPolicy = lazy(() => import("./website/PrivacyPolicy"));

// Protected Route

axios.defaults.withCredentials = true;

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.language);
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("language", lang);
    const savedLanguage = localStorage.getItem("language") || lang;
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      document.body.dir = savedLanguage === "ar" ? "rtl" : "ltr"; // Set direction
    }
  }, [i18n, lang]);
  console.log();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !user.user) {
      dispatch(isAuthenticated());
    } else {
      toast.error(error);
    }
    
  }, [dispatch, error, navigate, user.user]);
  return (
    <Routes>
      {/* website home*/}
      <Route path="/" element={<Weblayout />}>
        <Route index element={<Home />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="bLog" element={<BLog />} />
        <Route path="bLog/:id" element={<BLogDetails />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="complaints" element={<ComplaintForm />} />
        <Route path="ReturnPolicy" element={<ReturnPolicy />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget-password" element={<ForgetPassword />} />

      {/**Admin Dashboard  */}
      <Route path="/admin-dashboard/" element={<AdminDashboardLayout />}>
        <Route index element={<AdminDashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="employees" element={<Employees />} />
        <Route path="roles" element={<RolesPage />} />
        <Route path="packages" element={<PackagesAdmin />} />
        <Route path="add-package" element={<AddOrEditPackage />} />
        <Route path="packages/add-package" element={<AddOrEditPackage />} />
        <Route path="edit-package/:id" element={<EditPackageWrapper />} />
        <Route path="products" element={<AdminProductPage />} />
        <Route path="add-product" element={<AddProductPage />} />
        <Route path="blogs" element={<AdminBlogs />} />
        <Route path="categories" element={<AdminCategoriesPage />} />
        <Route path="notification" element={<Notifications />} />
        <Route path="tickets" element={<AdminTickets />} />
        <Route path="complaints" element={<Complaints />} />
      </Route>
      <Route path="/payment-success" element={<SuccessPayment />} />
      <Route path="/payment-failed" element={<ErrorPayment />} />
      {/** website Dashboard */}

      <Route path="/dashboard/" element={<DashBoardLayOut />}>
        <Route index element={<Dashboard />} />
        <Route path="products" element={<Products />} />
        <Route path="ShowProduct/:productId" element={<ShowProduct />} />
        <Route
          path="ShowGlobalProduct/:productId"
          element={<ShowGlobalProduct />}
        />
        <Route path="my-products" element={<MyProducts />} />
        <Route path="my-product/:productId" element={<EditProduct />} />
        <Route path="orders" element={<Orders />} />
        <Route path="added-products" element={<Addedproducts />} />
        <Route path="add-product" element={<Addproduct />} />
        <Route path="add-product/:productId" element={<Addproduct />} />
        <Route path="subscription-plan" element={<SubscriptionPlan />} />
        <Route path="subscription-plan/subscribe/:id" element={<Subscripe />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="add-ticket" element={<AddTicket />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="integrations" element={<Integrations />} />
        <Route path="profile" element={<EditProfile />} />
        <Route path="cart" element={<Cart />} />
      </Route>
    </Routes>
  );
}

export default App;
