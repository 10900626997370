// features/auth/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




// Register User
export const register = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/register`, userData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

// export const sendVerificationCode = createAsyncThunk(
//   "auth/sendVerificationCode",
//   async (data, { rejectWithValue }) => {
    
//   }
// );

// Login User
export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/login`, userData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      console.log(response.data);

      localStorage.setItem("token", JSON.stringify(response.data.access_token));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// isAuthenticated
export const isAuthenticated = createAsyncThunk(
  "auth/isAuthenticated",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/user/profile?lang=ar`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (response?.data?.message === "نأسف، باقتك قد انتهت. يرجى التجديد أو تغيير الباقة.") {
       
        toast.error(response?.data?.message);
      }
      return {
        user: response.data.user,
        store: response.data.store,
      };
      
    } catch (error) {
      console.log(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
    isAuthenticated: false,
    payment_url: "",
    store: null,
    status: null,
    user_id: "",
    isValid: false,
  },
  reducers: {
    logout: (state) => {
      state.user = null; // Handle logout
      state.isAuthenticated = false;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("paymentVisited");
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle Register
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.status = action.payload.valid;
      state.user_id = action.payload.user_id;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = action.payload.valid;
    })
    // Handle Login
    builder.addCase(login.pending, (state) => {
      state.user = null;
      state.loading = true;
      state.error = null;
      state.isAuthenticated = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handle isAuthenticated
    builder.addCase(isAuthenticated.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(isAuthenticated.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    });
    builder.addCase(isAuthenticated.rejected, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
      state.isValid = false;
      
    });
  },
});

export const { logout, setUser } = authSlice.actions;
export default authSlice.reducer;
