import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Vector from "/public/Icons/Vector2.png";
import link from "/public/Icons/link.svg";

import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../redux/reducer/authSlice";
import { addMeProduct } from "../redux/reducer/myProductsSlice";
import { globalProduct } from "../globalProduct";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ShowGlobalProduct = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sellingPrice, setSallingPrice] = useState();
  const itemsPerPage = 15;
  const { user } = useSelector((state) => state.auth);
  const product = globalProduct.find(
    (product) => product.id === Number(productId)
  );

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(isAuthenticated());
    }
  }, [dispatch]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleAddToMyProducts = () => {
    const data = {
      lang: "ar",
      product_id: product.id,
      selling_price: sellingPrice,
    };
    dispatch(addMeProduct(data));
  };

  return (
    <div className="p-4 md:p-8">
      <div className="bg-white rounded-lg shadow-lg p-4 md:p-6 flex flex-col lg:flex-row lg:gap-6">
        <div className="lg:w-1/3 w-full order-1 lg:order-2 mb-6 lg:mb-0">
          <div className="border rounded-lg p-4 flex flex-col">
            <div className="flex items-center justify-center">
              <img
                src={product.main_image}
                alt="product image"
                className="rounded-lg mb-4 bg-contain m-auto h-64 "
              />
            </div>
            <h2 className="text-lg font-semibold mb-2 text-center">
              {product.ar_title}{" "}
            </h2>
            <p className="text-gray-500 mb-2 text-center">{product.barcode}</p>
            <p className="text-gray-500 mb-4 text-center">
              {product.description}
            </p>
          </div>
          <button
            onClick={handleAddToMyProducts}
            className={`hidden md:block bg-menu-${user?.user?.user_type} w-full py-2 text-white rounded-full mt-3`}
          >
            إضافة
          </button>
        </div>
        <div className="lg:w-2/3 w-full order-2 lg:order-1">
          <h2 className="text-xl font-semibold mb-4">سعر المنتج</h2>
          <div className="border p-3 rounded-2xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">السعر الخاص</label>
                <input
                  type="text"
                  value={product.sale_price}
                  className="w-full border rounded-lg p-2"
                  disabled
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">
                  السعر على الموقع
                </label>
                <input
                  type="text"
                  placeholder={product?.suggested_price}
                  value={sellingPrice}
                  onChange={(e) => {
                    setSallingPrice(e.target.value);
                  }}
                  className="w-full border rounded-lg p-2"
                />
                <p className="text-red-500 text-sm mt-1">
                  اقل سعر للبيع {product.sale_price} ر.س
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center mb-2">
                <Switch {...label} />
                <label
                  htmlFor="changeDescription"
                  className="text-gray-700 ml-2"
                >
                  تم تغيير الوصف
                </label>
              </div>
              <div className="flex items-center mb-2">
                <Switch {...label} />
                <label htmlFor="changePrice" className="text-gray-700 ml-2">
                  تغيير السعر
                </label>
              </div>
              <div className="flex items-center mb-2">
                <Switch {...label} />
                <label htmlFor="changeName" className="text-gray-700 ml-2">
                  تغيير الإسم
                </label>
              </div>
            </div>
          </div>
          <h2 className="text-xl  my-6">تكاملات المنتج</h2>
          <div className="grid grid-cols-1 gap-4 border p-8">
            <div className="flex items-center justify-between">
              <div className="">
                <button className="bg-gray-200 px-2 py-3  rounded-lg ml-3">
                  <img src={Vector} alt="" />
                </button>
                <span className="text-gray-500">لم يتم الربط</span>
              </div>
              <img src={link} alt="" className="bg-green-100 p-2 rounded-md" />
            </div>
            <div className="flex items-center justify-between">
              <div className="">
                <button className="bg-gray-200 px-2 py-3  rounded-lg ml-3">
                  <img src={Vector} alt="" />
                </button>
                <span className="text-gray-500">لم يتم الربط</span>
              </div>
              <img src={link} alt="" className="bg-green-100 p-2 rounded-md" />
            </div>
            <div className="flex items-center justify-between">
              <div className="">
                <button className="bg-gray-200 px-2 py-3  rounded-lg ml-3">
                  <img src={Vector} alt="" />
                </button>
                <span className="text-gray-500">لم يتم الربط</span>
              </div>
              <img src={link} alt="" className="bg-green-100 p-2 rounded-md" />
            </div>
            <div className="flex items-center justify-between">
              <div className="">
                <button className="bg-gray-200 px-2 py-3  rounded-lg ml-3">
                  <img src={Vector} alt="" />
                </button>
                <span className="text-gray-500">لم يتم الربط</span>
              </div>
              <img src={link} alt="" className="bg-green-100 p-2 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={handleAddToMyProducts}
          className={`bg-menu-${user?.user?.user_type} flex justify-center md:hidden py-2 text-white rounded-full mt-4 md:mt-6 lg:mt-0 w-[90%]`}
        >
          إضافة
        </button>
      </div>
    </div>
  );
};

export default ShowGlobalProduct;
