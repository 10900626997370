import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userlogout from "/public/Icons/logout.svg";
import logo from "/public/Images/logo.png";
import logoWhite from "/public/Images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated, logout } from "../redux/reducer/authSlice";
import {
  getDashboardArrayFreePackage,
  getDashboardArraySeller,
  getDashboardArraySupplier,
  getSuperAdminArray,
} from "../navbarContent";
import axios from "axios";

const Sidebar = () => {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const freePackageMenu = getDashboardArrayFreePackage(t);
  const sellerMenu = getDashboardArraySeller(t);
  const supplierMenu = getDashboardArraySupplier(t);
  const superAdminMenu = getSuperAdminArray(t);

  const [permissions, setPermissions] = useState([]);

  const menuItems = getSuperAdminArray(t, permissions);
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("paymentVisited");
    navigate("/login", { replace: true });
  };

  // Only check authentication once when the component mounts
  useEffect(() => {
    dispatch(isAuthenticated());
  }, [dispatch]);

  // Fetch permissions if user type is 'super_admin'
  useEffect(() => {
    if (user?.user?.user_type === "super_admin") {
      const token = localStorage.getItem("token");
      axios
        .get(
          `https://backend-sbyp.sbyp-sa.com/api/roles/permissions?role_name=${user?.user?.roles[0]?.name}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setPermissions(response.data.data.map((item) => item.name));
          }
        })
        .catch((error) => {
          console.error("Error fetching permissions:", error);
        });
    }
  }, [user?.user?.user_type, user?.user?.roles]);

  const filteredSuperAdminMenu = permissions.length
    ? superAdminMenu.filter((item) =>
        permissions.includes(item.text.toLowerCase())
      )
    : [];

  const userTypeColors = {
    super_admin: "bg-menu-super_admin",
    seller: "bg-menu-seller",
    supplier: "bg-menu-supplier",
  };

  return (
    <aside
      className={`${
        location.pathname === "/login" ? "hidden lg:hidden" : " hidden lg:flex"
      } w-1/6 fixed  flex-col justify-between  bg-white  py-6 px-5 h-screen`}
    >
      <div className="flex flex-col">
        <Link to="/">
          {user?.user?.user_type === "supplier" ||
          user?.user.user_type === "super_admin" ? (
            <img src={logo} className="max-w-[100px] mb-4" alt="logo" />
          ) : (
            <img src={logo} className="max-w-[100px] mb-4" alt="logo" />
          )}
        </Link>

        {user?.user.user_type === "super_admin" ? (
          <ul className="flex flex-col mb-4">
            {menuItems.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className={`relative py-2 px-2 mb-2 text-l   hover:rounded-full hover:text-white hover:bg-menu-super_admin  flex align-center items-center ${
                  location.pathname === item.path
                    ? `text-white rounded-full bg-menu-super_admin`
                    : ""
                }`}
              >
                <div className="text-xl mx-1">
                  {location.pathname === item.path
                    ? item.iconDark
                    : item.iconWhite}
                </div>
                {item.text}
                {location.pathname === item.path ? (
                  language === "en" ? (
                    <IoIosArrowForward className="inline text-xl absolute right-2  top-3" />
                  ) : (
                    <IoIosArrowBack className="inline text-xl absolute left-2 top-3" />
                  )
                ) : (
                  ""
                )}
              </Link>
            ))}
          </ul>
        ) : user?.user.user_type === "supplier" &&
          user.user.end_date !== null ? (
          <ul className="flex flex-col mb-6">
            {supplierMenu.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className={`relative py-2 px-2   hover:rounded-full  hover:text-white hover:bg-menu-default flex align-center items-center mb-1 ${
                  location.pathname === item.path
                    ? `text-white rounded-full bg-menu-default`
                    : ""
                }`}
              >
                <div className="text-xl mx-1">
                  {location.pathname === item.path
                    ? item.iconDark
                    : item.iconWhite}
                </div>
                {item.text}
                {location.pathname === item.path ? (
                  language === "en" ? (
                    <IoIosArrowForward className="inline text-xl absolute right-2  top-3" />
                  ) : (
                    <IoIosArrowBack className="inline text-xl absolute left-2 top-3" />
                  )
                ) : (
                  ""
                )}
              </Link>
            ))}
          </ul>
        ) : user?.user.user_type === "seller" &&
          user?.user.package_price === 0.0 ? (
          <ul className="flex flex-col mb-6">
            {freePackageMenu.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className={`relative py-2 px-2 text-l hover:text-white hover:rounded-full hover:bg-menu-seller flex align-center items-center mb-1 ${
                  location.pathname === item.path
                    ? "text-white rounded-full bg-menu-seller"
                    : ""
                }`}
              >
                <div className="text-xl mx-1">
                  {location.pathname === item.path
                    ? item.iconDark
                    : item.iconWhite}
                </div>
                {item.text}
                {location.pathname === item.path ? (
                  language === "en" ? (
                    <IoIosArrowForward className="inline text-xl absolute right-2  top-3" />
                  ) : (
                    <IoIosArrowBack className="inline text-xl absolute left-2 top-3" />
                  )
                ) : (
                  ""
                )}
              </Link>
            ))}
          </ul>
        ) : (
          <ul>
            {sellerMenu.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className={`relative py-2 px-2 text-l hover:text-white hover:rounded-full hover:bg-menu-seller flex align-center items-center mb-1 ${
                  location.pathname === item.path
                    ? "text-white rounded-full bg-menu-seller"
                    : ""
                }`}
              >
                <div className="text-xl mx-1">
                  {location.pathname === item.path
                    ? item.iconDark
                    : item.iconWhite}
                </div>
                {item.text}
                {location.pathname === item.path ? (
                  language === "en" ? (
                    <IoIosArrowForward className="inline text-xl absolute right-2  top-3" />
                  ) : (
                    <IoIosArrowBack className="inline text-xl absolute left-2 top-3" />
                  )
                ) : (
                  ""
                )}
              </Link>
            ))}
          </ul>
        )}
      </div>

      <div>
        <button
          onClick={handleLogout}
          className="flex items-center mt-4 w-full  hover:bg-red-100 py-2 px-4 rounded-full"
        >
          <img src={userlogout} alt="" className="h-6 w-6" />
          <p className="mx-2 text-md text-red-500"> تسجيل الخروج</p>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
